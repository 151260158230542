import { BASEURL, GetCallRecordsByStaff, VOXBAYBASEURL } from "../api";

import axios from "axios";

const CallApiServices = axios.create({
  baseURL: VOXBAYBASEURL,
});

const ApiServices = axios.create({
  baseURL: BASEURL,
});

export const PhonetoPhoneCall = async (callParams) => {
  try {
    const response = await CallApiServices.get("clicktocall.php", {
      params: {
        uid: "gryon7vgtr",
        pin: "aderv6vkiy",
        source: callParams.source_number,
        destination: callParams.destination_number,
        ext: callParams.extension_number,
        callerid: callParams.did_number,
      },
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.status >= 200 && response.status < 300) {
      return response; // Return success data
    } else {
      throw new Error(`Server returned status ${response.status}`);
    }
  } catch (error) {
    console.error("Request error:", error);
    throw error; // Re-throw the error to be caught in the calling component
  }
};

export const CRMtoPhoneCall = async (callParams) => {
  try {
    const response = await CallApiServices.get("clicktocall.php", {
      params: {
        uid: "gryon7vgtr",
        pin: "aderv6vkiy",
        source: callParams.source_number,
        destination: callParams.destination_number,
        ext:callParams.extension_number,
        callerid: callParams.did_number,
      },
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.status >= 200 && response.status < 300) {
      return response; // Return success data
    } else {
      throw new Error(`Server returned status ${response.status}`);
    }
  } catch (error) {
    console.error("Request error:", error);
    throw error; // Re-throw the error to be caught in the calling component
  }
};

export const GetCallRecordByStaff =  async (requestData) => {
  try {
    //   let token = localStorage.getItem("authToken");
    //   console.log("current token", token);
    //   if (!token) {
    //     navigate("/");
    //     throw new Error("Authentication token not found");
    //   }
    const response = await ApiServices.post(GetCallRecordsByStaff, requestData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
 

    //   console.log("All Available Headers:", response.headers);
    //   // Check if the Authorization header is present in the response
    //   const authorizationHeader = response.headers.get("Authorization");
    //   console.log("Updated Token:", authorizationHeader);
    //   console.log("All Headers:", response.headers);
    //   if (authorizationHeader) {
    //     // Save the token to local storage
    //     localStorage.setItem("authToken", authorizationHeader);
    //     console.log("Updated Token:", authorizationHeader);
    //   }

    if (response.status >= 200 && response.status < 300) {
      const userData = response.data;
      return userData; // Return success data
    } else {
      throw new Error(`Server returned status ${response.status}`);
    }
  } catch (error) {
    console.error("Request error:", error);
    throw error;
  }
};
