export const BASEURL = "https://whale-app-9xfqu.ondigitalocean.app";
// export const BASEURL = "http://0.0.0.0:80";
export const Login = "/user/login"
export const USERS = "/user/getall"
export const GetUser = "/user/getall"
export const AddUser = "/user/create"
export const GetCountries = "/country/mincountries"
export const GetLeads = "/user/getall"
export const GetLeadsByStaff = "/leads/getleadsbyuser/"
export const UpdateLead = "/user/updatelead"
export const GetAllStaffs = "/user/getstaffs"
export const GetDepartments = "/user/getdept"
export const GetDesignations = "/user/getdesignation"
export const GetCallStatus = "/leads/getcallstatus"
export const LeadCallUpdate = "/leads/callstatusupdate"

export const GetLeadInfoById = "/leads/getleadinfo/"

export const GetRescheduledLeadsByStaff = "/leads/getrescheduledbystaff/"
export const GetNewLeadsByStaff = "/leads/geunattendedleadbystaff/"
export const GetTodaysLeadsByStaff = "/leads/gettodayslead/"
export const GetRedirectedLeadByStaff = "/leads/getredirectedleadbystaff/"

export const GiveUpLead = "/leads/giveup"
export const studentProfileUpdate = "/leads/createprofile"

export const StaffwiseCallStatus = "/reports/getstaffwisereport"
export const StaffWiseLeadCount = "/leads/staffwisecallcount/"
export const NameUpdate = "/leads/updatename"
export const GetReportByStatusKey = "/reports/getbykey"

export const UploadBulkLeadApi = "/leads/createbulkleadbystaff"
export const AddStudentApi = "/leads/addstudentbystaff"

export const GetReportByFilterApi="/reports/getbyfilter"

export const GetAllSource="/user/getallsource"
export const GetAllSourceInfo="/user/getallsourceinfo"
export const GetAllCountry="/user/getallcountry"

export const VOXBAYBASEURL = "https://pbx.voxbaysolutions.com/api/";

export const GetCallRecordsByStaff="/getcallrecordsbystaff"


